import Cookies from 'js-cookie';

// https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
export const clearAllCookies = () => {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const setTokenCookie = (jwt) => {
  Cookies.set('nf_jwt', jwt, {
    expires: 30,
    path: '/',
    secure: true,
    domain: window.location.hostname,
    HttpOnly: false,
  });
};

export const refreshAndSetToken = async () => {
  try {
    const jwt = await window.netlifyIdentity.currentUser().jwt(true);
    setTokenCookie(jwt);
  } catch (e) {
    console.error(e);
  }
};