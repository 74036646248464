import React, { useEffect } from 'react';
import { refreshAndSetToken, clearAllCookies } from './utils';
import Cookies from 'js-cookie';

const isLogout = window.location.href.match(/\?logout/);
if (isLogout) {
  clearAllCookies();
}

const checkRdrToHome = () => {
  const pathname = window.location.pathname;
  if (pathname !== '/' && !isLogout) {
    Cookies.remove('nf_jwt');
    localStorage.removeItem('gotrue.user');
    window.location.href = '/?rdr=' + encodeURIComponent(pathname);
    return true;
  }
  const matchedRdr = window.location.href.match('rdr=([^&]+)');
  if (matchedRdr) {
    window.NUI_RDR = decodeURIComponent(matchedRdr[1]);
  }
};

const redirectToContent = async () => {
  await refreshAndSetToken();
  if (window.NUI_RDR) {
    window.location.href = window.NUI_RDR;
  } else {
    window.location.href = '/admin';
  }
};

const init = () => {
  const user = window.netlifyIdentity.currentUser();
  if (user && !isLogout) {
    redirectToContent();
  }
};

const Login = () => {
  useEffect(() => {
    if (checkRdrToHome()) return;
    setTimeout(() => window.netlifyIdentity.open(), 100);
    if (!window.NUI_NF_SETUP) {
      window.NUI_NF_SETUP = true;
      window.netlifyIdentity.on('close', () => window.netlifyIdentity.open());
      window.netlifyIdentity.on('logout', () => {
        Cookies.remove('nf_jwt');
        localStorage.clear();
      });
      window.netlifyIdentity.on('login', () => {
        redirectToContent();
      });
      setTimeout(init, 100);
    }
  }, []);

  return <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundImage: 'url("https://source.unsplash.com/collection/10519435")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
  }}>
  </div>
};

export default Login;